import React from "react";
import Slider from "react-slick";
import { AiFillGithub, AiFillFilePdf, AiOutlineGlobal } from "react-icons/ai";
import { BiBook } from "react-icons/bi"; // Book icon for peer-reviewed paper
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProjectTile = ({
  image,
  videos = [], // Ensure videos has a default empty array
  title,
  description,
  link,
  linkText,
  tags,
  date,
  key,
}) => {
  const formatDescription = (text) => {
    if (!text) return "";
    return text.split("\n").map((item, index) => (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    ));
  };

  const settings = {
    dots: videos?.length > 1,
    lazyLoad: true,
    infinite: videos?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div>
        <ul className="slick-dots custom-dots">{dots}</ul>
      </div>
    ),
    customPaging: () => (
      <div className="w-4 h-4 bg-gray-400 hover:bg-gray-600 rounded-full transition duration-300"></div>
    ),
  };

  const iconMap = {
    "GitHub Repository": <AiFillGithub className="inline-block mr-2 text-xl" />,
    "Access PDF Report": (
      <AiFillFilePdf className="inline-block mr-2 text-xl" />
    ),
    "Access Peer-Reviewed Paper": (
      <BiBook className="inline-block mr-2 text-xl" />
    ),
    default: <AiOutlineGlobal className="inline-block mr-2 text-xl" />, // Generic website icon
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden border border-gray-200 transition-transform transition-shadow duration-300 hover:shadow-xl hover:-translate-y-1 hover:bg-gray-50 flex flex-col">
      {/* Image or Video Section */}
      {image ? (
        <img
          src={image}
          alt={title}
          className="w-full h-56 object-cover bg-gray-100"
        />
      ) : videos?.length > 0 ? ( // Check if videos exists and has elements
        <Slider {...settings} key={key}>
          {videos.map((video, index) => (
            <div key={index} className="relative pb-56">
              <iframe
                className="absolute inset-0 w-full h-full rounded-lg"
                src={video}
                title={`${title} Video ${index + 1}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                loading="lazy"
              ></iframe>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="w-full h-56 flex items-center justify-center bg-gradient-to-r from-gray-800 via-gray-700 to-gray-600">
          <p className="text-white font-semibold">No Media</p>
        </div>
      )}

      {/* Content Section */}
      <div className={`p-6 flex-grow ${videos?.length > 1 ? "mt-8" : ""}`}>
        <h2 className="text-xl font-bold mb-3">{title}</h2>
        <p className="text-gray-600 mb-4">{formatDescription(description)}</p>
        {date && <p className="text-gray-400 text-sm mb-4">{date}</p>}

        {/* Show link only if it exists */}
        {link && (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 font-semibold transition-colors duration-300 flex items-center"
          >
            {iconMap[linkText] || iconMap.default}
            {linkText}
          </a>
        )}
      </div>

      {/* Tags Section - Moved to Bottom */}
      {tags?.length > 0 && (
        <div className="p-4 bg-gray-100 border-t border-gray-200 flex flex-wrap gap-2">
          {tags.map((tag, index) => (
            <span
              key={index}
              className="bg-blue-200 text-gray-700 px-3 py-1 rounded-full text-sm font-medium shadow-sm"
            >
              {tag}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectTile;
